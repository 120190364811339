@import url('https://fonts.googleapis.com/css?family=Barlow:400,400i,500,500i');
.App {
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.searchLine {
    /* position: absolute; */
    /* left: 0;
  right: 0; */
    /* top: 70px; */
    margin: 0 -30px 10px -30px !important;
}

.searchLine__bottom {
    position: absolute;
    bottom: 75px;
    left: 0;
    right: 0;
}

.searchLine__table {
    position: absolute;
    left: 0;
    right: 0;
}

.searchLine__toolbar {
    top: 70px;
}

.verticalLine {
    width: 1px;
    height: 100% !important;
    background: #e7edee;
    margin: 0 !important;
    margin-right: 20px !important;
}

.verticalLineFilter {
    height: auto !important;
}

.pageTitle {
    color: #27498f !important;
    font-weight: 800 !important;
    font-size: 30px !important;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}

.formLabel {
    color: #27cfda !important;
    font-weight: 700 !important;
    margin-bottom: 16px !important;
    font-size: 18px !important;
}

.blueButton {
    background-color: #27498f !important;
    color: #fff !important;
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    border-radius: 24px !important;
    font-size: 18px !important;
}

.blueButton__specific {
    display: flex !important;
    width: 150px !important;
    justify-content: space-between !important;
}

.blueButton:hover {
    color: #fff !important;
    background-color: #27cfda !important;
}

.margin10 {
    margin-right: 15px!important;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
    background-color: #f2f7f8;
    border-color: #e7edee;
}

.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
    border-color: #e7edee !important;
    background-color: #fafcfc;
    color: #27498f;
    font-family: Calibri, sans-serif;
    font-size: 18px;
    font-weight: 400;
}

.ck.ck-icon,
.ck.ck-icon *,
.ck.ck-dropdown .ck-button.ck-dropdown__button {
    color: #27498f !important;
}

.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
    font-size: 16px !important;
}

.ck-editor {
    border-radius: 3px;
    border: 1px solid #e7edee;
    background-color: #fafcfc;
}

/* [dir='ltr']
    .ck.ck-dropdown
    .ck-button.ck-dropdown__button:not(.ck-button_with-text) {
    display: none;
} */

table {
    border-collapse: collapse;
    width: 100%;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
